dmx.Component('serverconnect-form', {

    extends: 'form',

    initialData: {
        status: 0,
        data: null,
        headers: {},
        state: {
            executing: false,
            uploading: false,
            processing: false,
            downloading: false
        },
        uploadProgress: {
            position: 0,
            total: 0,
            percent: 0
        },
        downloadProgress: {
            position: 0,
            total: 0,
            percent: 0
        },
        lastError: {
            status: 0,
            message: '',
            response: null
        }
    },

    attributes: {
        timeout: {
            type: Number,
            default: 0 // timeout in seconds
        },

        autosubmit: {
            type: Boolean,
            default: false
        },

        params: {
            type: Object,
            default: {}
        },

        headers: {
            type: Object,
            default: {}
        },

        'post-data': { // only for method post, not for get
            type: String,
            default: 'form' // form, json (default uses form data)
        }
    },

    methods: {
        abort: function() {
            this.abort();
        }
    },

    events: {
        start: Event, // when starting an ajax call
        done: Event, // when ajax call completed (success and error)
        error: Event, // server error or javascript error (json parse or network transport) or timeout error
        unauthorized: Event, // 401 status from server
        forbidden: Event, // 403 status from server
        abort: Event, // ajax call was aborted
        success: Event, // successful ajax call,
        upload: ProgressEvent, // on upload progress
        download: ProgressEvent // on download progress
    },

    $parseAttributes: function(node) {
        dmx.BaseComponent.prototype.$parseAttributes.call(this, node);
        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'param' && attr.argument) {
                this.$addBinding(attr.value, function(value) {
                    this.props.params[attr.argument] = value;
                });
            }
            if (attr.name == 'header' && attr.argument) {
                this.$addBinding(attr.value, function(value) {
                    this.props.headers[attr.argument] = value;
                });
            }
        }, this);
    },

    render: function(node) {
        this.xhr = new XMLHttpRequest();
        this.xhr.addEventListener('load', this.onload.bind(this));
        this.xhr.addEventListener('abort', this.onabort.bind(this));
        this.xhr.addEventListener('error', this.onerror.bind(this));
        this.xhr.addEventListener('timeout', this.ontimeout.bind(this));
        this.xhr.addEventListener('progress', this.onprogress('download').bind(this));
        if (this.xhr.upload) this.xhr.upload.addEventListener('progress', this.onprogress('upload').bind(this));

        var checkValidity = node.checkValidity;

        node.dmxExtraData = {};
        node.dmxExtraElements = [];
        node.checkValidity = function() {
            for (var i = 0; i < node.dmxExtraElements.length; i++) {
                if (node.dmxExtraElements[i].validate) {
                    node.dmxExtraElements[i].validate();
                }
            }

            return checkValidity.call(node);
        };

        dmx.Component('form').prototype.render.call(this, node);

        if (this.props.autosubmit) {
            dmx.nextTick(function() {
                this.submit();
            }, this);
        }
    },

    abort: function() {
        this.xhr.abort();
    },

    _submit: function(extra) {
        this.xhr.abort();

        var method = this.$node.method.toUpperCase();
        var action = this.$node.action;
        var data = null;

        var qs = Object.keys(this.props.params).filter(function(key) {
            return this.props.params[key] != null;
        }, this).map(function(key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(this.props.params[key]);
        }, this).join('&');

        if (method == 'GET') {
            qs += dmx.array(this.$node.elements).filter(function(element) {
                return (!(extra && extra[element.name])) && !element.disabled && ((element.type !== 'radio' && element.type !== 'checkbox') || element.checked);
            }).map(function(element) {
                return encodeURIComponent(element.name) + '=' + encodeURIComponent(element.value);
            }).join('&');

            if (extra) {
                Object.keys(extra).forEach(function(key) {
                    if (Array.isArray(extra[key])) {
                        extra[key].forEach(function(value) {
                            qs += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(value);
                        });
                    } else {
                        qs += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(extra[key]);
                    }
                });
            }
        } else {
            if (this.props['post-data'] == 'json') {
                data = {};

                dmx.array(this.$node.elements).forEach(function(element) {
                    if (element.name && !element.disabled) {
                        if (element.type.toLowerCase == 'radio') {
                            var checked = document.querySelector('input[name="' + element.name + '"]:checked');

                            if (checked) {
                                data[element.name] = checked.value;
                            }
                        } else if (element.type == 'checkbox') {
                            var group = document.querySelectorAll('input[name="' + element.name + '"]');

                            if (group.length) {
                                if (group.length == 1) {
                                    data[element.name] = group[0].checked;
                                } else {
                                    data[element.name] = dmx.array(group).filter(function(checkbox) {
                                        return checkbox.checked;
                                    }).map(function(checkbox) {
                                        return checkbox.value;
                                    });
                                }
                            }
                        } else if (element.type == 'select-multiple') {
                            data[element.name] = dmx.array(element.selectedOptions).map(function(option) {
                                return option.value;
                            });
                        } else if (element.type == 'file') {
                            if (element.files.length) {
                                if (element.multiple) {
                                    data[element.name] = element.files.map(function(file) {
                                        return file.name;
                                    });
                                } else {
                                    data[element.name] = element.files[0].name;
                                }
                            }
                        } else {
                            data[element.name] = element.value;
                        }
                    }
                });

                if (extra) {
                    Object.assign(data, extra);
                }

                if (this.$node.dmxExtraData) {
                    Object.assign(data, this.$node.dmxExtraData);
                }

                this.props.headers['Content-Type'] = 'application/json';
                data = JSON.stringify(data);
            } else {
                data = new FormData(this.$node);

                if (extra) {
                    Object.keys(extra).forEach(function(key) {
                        if (Array.isArray(extra[key])) {
                            if (!/\[\]$/.test(key)) {
                                key += '[]';
                            }
                            value.forEach(function(val) {
                                data.append(key, val);
                            }, this);
                        } else {
                            data.set(key, extra[key]);
                        }
                    }, this);
                }

                if (this.$node.dmxExtraData) {
                    Object.keys(this.$node.dmxExtraData).forEach(function(key) {
                        var value = this.$node.dmxExtraData[key];

                        if (Array.isArray(value)) {
                            if (!/\[\]$/.test(key)) {
                                key += '[]';
                            }
                            value.forEach(function(val) {
                                data.append(key, val);
                            }, this);
                        } else {
                            data.set(key, value);
                        }
                    }, this);
                }
            }
        }

        this._reset();
        this.dispatchEvent('start');

        this.set('state', {
            executing: true,
            uploading: false,
            processing: false,
            downloading: false
        });

        this.xhr.open(method, action + (qs ? (action.indexOf('?') > -1 ? '&' : '?') + qs : ''));
        this.xhr.timeout = this.props.timeout * 1000;
        Object.keys(this.props.headers).forEach(function(header) {
            this.xhr.setRequestHeader(header, this.props.headers[header]);
        }, this);
        this.xhr.setRequestHeader('accept', 'application/json');
        try { this.xhr.send(data); }
        catch (err) { this._done(err); }
    },

    _reset: function() {
        this.set({
            status: 0,
            headers: {},
            state: {
                executing: false,
                uploading: false,
                processing: false,
                downloading: false
            },
            uploadProgress: {
                position: 0,
                total: 0,
                percent: 0
            },
            downloadProgress: {
                position: 0,
                total: 0,
                percent: 0
            },
            lastError: {
                status: 0,
                message: '',
                response: null
            }
        });
    },

    _done: function(err) {
        this._reset();

        if (err) {
            this.set('lastError', {
                status: 0,
                message: err.message,
                response: null
            });

            this.dispatchEvent('error');
        } else {
            var response = this.xhr.responseText;

            try {
                response = JSON.parse(response);
            } catch(err) {
                if (this.xhr.status < 400) {
                    this.set('lastError', {
                        status: 0,
                        message: 'Response was not valid JSON',
                        response: response
                    });

                    this.dispatchEvent('error');
                    return;
                }
            }

            try {
                // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders#Example
                var strHeaders = this.xhr.getAllResponseHeaders();
                var arrHeaders = strHeaders.trim().split(/[\r\n]+/);

                this.set('headers', arrHeaders.reduce(function(headers, line) {
                    var parts = line.split(': ');
                    var header = parts.shift();
                    var value = parts.join(': ');

                    headers[header] = value;

                    return headers;
                }, {}));
            } catch(err) {
                console.warn('Error parsing response headers', err);
            }

            this.set('status', this.xhr.status);

            if (dmx.validateReset) {
                dmx.validateReset(this.$node);
            }
            
            if (window.grecaptcha && this.$node.querySelector('.g-recaptcha')) {
                grecaptcha.reset();
            }
    
            if (this.xhr.status < 400) {
                this.set('data', response);
                this.dispatchEvent('success');
            } else {
                // some other server error
                this.set('lastError', {
                    status: this.xhr.status,
                    message: this.xhr.statusText,
                    response: response
                });

                if (this.xhr.status == 400) {
                    // validation error
                    this.dispatchEvent('invalid');

                    if (response.form) {
                        for (var name in response.form) {
                            var element = document.querySelector('[name="' + name + '"]');
                            if (element) {
                                element.setCustomValidity(response.form[name]);
                                
                                dmx.requestUpdate();
                                
                                if (dmx.bootstrap4forms) {
                                    dmx.validate.setBootstrap4Message(element, response.form[name]);
                                } else if (dmx.bootstrap3forms) {
                                    dmx.validate.setBootstrapMessage(element, response.form[name]);
                                } else {
                                    dmx.validate.setErrorMessage(element, response.form[name]);
                                }
                            }
                        }
                    } else {
                        console.warn('400 error, no form errors in response.', response);
                    }
                } else if (this.xhr.status == 401) {
                    this.dispatchEvent('unauthorized');
                } else if (this.xhr.status == 403) {
                    this.dispatchEvent('forbidden');
                } else {
                    this.dispatchEvent('error');
                }
            }
        }

        this.dispatchEvent('done');
    },

    onload: function(event) {
        this._done();
    },

    onabort: function(event) {
        this._reset();
        this.dispatchEvent('abort');
        this.dispatchEvent('done');
    },

    onerror: function(event) {
        this._done({ message: 'Failed to execute' });
    },

    ontimeout: function(event) {
        this._done({ message: 'Execution timeout' });
    },

    onprogress: function(type) {
        return function(event) {
            event.loaded = event.loaded || event.position;

            var percent = event.lengthComputable ? Math.ceil(event.loaded / event.total * 100) : 0;

            this.set('state', {
                executing: true,
                uploading: type == 'upload' && percent < 100,
                processing: type == 'upload' && percent == 100,
                downloading: type == 'download'
            });

            this.set(type + 'Progress', {
                position: event.loaded,
                total: event.total,
                percent: percent
            });

            this.dispatchEvent(type, {
                lengthComputable: event.lengthComputable,
                loaded: event.loaded,
                total: event.total
            });
        };
    }

});
